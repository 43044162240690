import { LogoContainer } from "./LogoContainer";
import { NavBar } from "../components/NavBar";
import { NavRadial } from "../components/NavRadial";
import { MenuDepartments } from "../components/MenuDepartments";
import { MenuFavorites } from "../components/MenuFavorites";
import { ModalTipology } from "../components/ModalTipology";
import styled from "styled-components";
import { useTransition } from "@react-spring/web";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { TourContainer } from "./TourContainer";
import { MenuDaytimeDown } from "../components/MenuDayTimeDown";
import { Department } from "../utils/Department";
import { TouchesUI } from "../components/TouchesUI";
import { MenuHelper } from "../components/MenuHelper";

export const ContainerUI = ()=>{

    const { state, setState } = useContext(AppContext);

    const [ daytrigger, setDayTrigger ] = useState([]);
    const [ deptrigger, setDepTrigger ] = useState([]);
    const [ favtrigger, setFavTrigger ] = useState([]);
    const [ helpertrigger, setHelperTrigger ] = useState([]);

    const transitionDayTime = useTransition(daytrigger, {
        from: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 0,
        },
        config:{
            duration: 250,
        }
    });

    const transitions = useTransition(state.tours, {
        from: {
            opacity: 0,
            scale: 0
        },
        enter: {
            opacity: 0,
            scale: 1
        },
        leave: {
            opacity: 0,
            scale: 0
        },
        config:{
            duration: 250,
        }
    });

    const transitionDeptList = useTransition(deptrigger, {
        from:{ 
            left: "-100%",
            opacity: 0
        },
        enter:{ 
            left: "0",
            opacity: 1
        },
        leave:{ 
            left: "-100%",
            opacity: 0
        },
    })
    
    const transitionFavList = useTransition(favtrigger, {
        from:{ 
            left: "-100%",
            opacity: 0
        },
        enter:{ 
            left: "0%",
            opacity: 1
        },
        leave:{ 
            left: "-100%",
            opacity: 0
        }
    })
    
    const transitionHelper = useTransition(helpertrigger, {
        from:{ 
            right: "-100%",
            opacity: 0
        },
        enter:{ 
            right: "0%",
            opacity: 1
        },
        leave:{ 
            right: "-100%",
            opacity: 0
        }
    })

    const closeTour = (label)=>{
        setState(prev=>prev.dropTour(label));
    }

    useEffect(()=>{
        let request = async()=>{
            let data = await state.api.get();
            let departments  = data.map(({ uuid, type, floor, area, avaliability, vtUrl })=>{
                return new Department(uuid, type, floor, area, vtUrl, avaliability, uuid);
            });
            setState(prev=>prev.setData(departments));
        }

        request();
    }, []);

    useEffect(()=>{
        if(state.listDeptIsActive){
            setDepTrigger([1]);
        }
        else{
            setDepTrigger([]);
        }
    }, [ state.listDeptIsActive ]);

    useEffect(()=>{
        if(state.listFavoritesIsActive){
            setFavTrigger([1]);
        }
        else{
            setFavTrigger([]);
        }
    }, [state.listFavoritesIsActive]);
    
    useEffect(()=>{
        if(state.isDaytimeDownActive){
            setDayTrigger([1]);
        }
        else{
            setDayTrigger([]);
        }
    }, [ state.isDaytimeDownActive ]);
    
    useEffect(()=>{
        if(state.listDeptIsActive || state.listFavoritesIsActive || state.departments3DSelector || state.isAmenities){
            setHelperTrigger([1]);
        }
        else{
            setHelperTrigger([]);
        }
    }, [ state.listDeptIsActive, state.listFavoritesIsActive, state.departments3DSelector, state.isAmenities ]);

    return(
        <>
            <LogoContainer />
            <NavBar />
            <NavRadial />
            {
                transitionDeptList((style, _)=>{
                    return <MenuDepartments style={ style } toggle={ ()=>{ setState(state.toggleDeptList() ) } }  />
                })
            }
            {
                transitionFavList((styles, _)=>{
                    return <MenuFavorites styles={ styles }/>
                })
            }
            {/* <MenuFavorites /> */}
            { 
                transitions((style, item)=>{
                    
                    return <TourContainer 
                    url={ item.url } 
                    label={ item.label } 
                    trasitionStyle={ style }
                    closeAction={ ()=>{ closeTour(item.label) } }
                    />
                })
            }
            {/* <ModalTipology /> */}
            {/* <Details>
                touch points: { navigator.maxTouchPoints }
            </Details> */}
            { 
                transitionDayTime((style, _)=>{
                    return <MenuDaytimeDown
                    style={ style }
                    />
                })
            }
            {
                transitionHelper((style, _)=>{
                    return <MenuHelper 
                        style={ style }
                    />
                })
            }
            <TouchesUI />
        </>
    );
};

const Details = styled.div`
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

`;
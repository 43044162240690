import data from '../utils/db.json';

export class API{
    constructor(){
        this.url = 'http://192.168.1.108:3200/';
        // this.url = 'http://192.168.1.238:3200/';
        this.floor = '';
        this.type = '';
    }

    async get(){
        // const res = await fetch(this.url + "departments" + "");
        // return await res.json();
        return data.departments;
    }

    async apartmentType(){
        const res = await fetch(this.url + "type");
        return await res.json();
    }


}
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from './theme/theme';
import { MainContainer } from './containers/MainContainer';
import { AppProvider } from './context/AppContext';


function App() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={ theme }>
        <AppProvider>
          <MainContainer />
        </AppProvider>
      </ThemeProvider>
    </>
  );
};

  // FUNCINALIDADES
  // # Asoleamiento <CLima> ri/RiBox2Line ri/RiHazeLine fi/FiSun fi/FiSunrise ri/RiSunCloudyLine go/GoSun ri/RiSunLine go/GoMoon ri/RiMoonClearLine ri/RiMoonLine
  // <span class="material-symbols-outlined">
  // routine
  // </span>

  // # Puntos de Interés <Vecindario> gr/GrMapLocation fa/FaRoute
  //    -
  // # Diponibilidad de Apartamentos <Apartamentos> ri/RiBuilding2Line

export default App;

export class DepartmentFactory{
    constructor(){
        this.geometry = {};
        
        if(DepartmentFactory.instance == null){
            DepartmentFactory.instance = this;
            return this;
        }
        
        return DepartmentFactory.instance;
    }

    addGeometry(label, geometry){
        this.geometry[label] = geometry;
        return this;
    };

    getGeometry(type){
        return this.geometry[type];
    }

    static instance = null;
    static T01 = "X01"
    static T02 = "X02"
    static T03 = "X03"
    static T04 = "X04"
    static T05 = "X05"
    static T06 = "X06"
    static T07 = "X07"
    static T08 = "X08"
    static T09 = "X09"
    static T10 = "X10"
    static T11 = "X11"
    static T12 = "X12"
    static T13 = "X13"
    static T13N2 = "X13N2"
    static T14 = "X14"
    static T15 = "X15"
    static T16 = "X16"
    static T16N2 = "X16N2"
    static T17 = "X17"
    static T18 = "X18"
    static TPH_A = "XPH_A"
    static TPH_B = "XPH_B"
    static TPH_C = "XPH_C"
    static A_GYM = "A_GYM"
    static A_LUDOTECA = "A_LUDOTECA"
    static A_ROOF = "A_ROOF"
    static A_SALON = "A_SALON"
    static A_S_LOUNGE = "A_S_LOUNGE"
    static A_S_PLAY = "A_S_PLAY"
    static A_S_WORK = "A_S_WORK"
    static A_SUM = "A_SUM"
    static A_TERRAZA = "A_TERRAZA"
    static A_WELLNESS = "A_WELLNESS"
    static CUTTER = "CUTTER"
}
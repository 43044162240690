import styled from "styled-components";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { MenuButton } from "./ButtonMenu";

export const NavBar = ()=>{

    const { state, setState } = useContext(AppContext);

    const openMenuApartments = ()=>{
        setState(state.toggleDeptList());
    }

    const updateVisibility = ()=>{
        setState(state.updateCityVisibility());
    }

    const openMenuDaytime = ()=>{
        setState(state.toggleDaytimeDown());
    }

    const fullScreen = ()=>{
        setState(state.toggleFullscreen());
    }

    return(
        <MenuWrapper>
            <MenuActions>
                <ContainerActions>
                    <ActionsContainer>
                        {/* <MenuButton $width={"20%"} $maxWidth={"60px"} $tag={"transport"} $fontSize={"80%"} $action={ fullScreen }>
                            <span className="material-symbols-outlined">
                                commute
                            </span>
                        </MenuButton> */}
                        <MenuButton $width={"20%"} $maxWidth={"60px"} $tag={ state.isFullscreen ? "window" : "fullscreen"} $fontSize={"80%"} $action={ fullScreen }>
                            <span className="material-symbols-outlined">
                                { state.isFullscreen ? "zoom_in_map" : "zoom_out_map" }
                            </span>
                        </MenuButton>
                        {/* <MenuButton $width={"20%"} $maxWidth={"60px"} $tag={"ruotes"} $fontSize={"80%"}>
                            <span className="material-symbols-outlined">
                                arrow_split
                            </span>
                        </MenuButton>
                        <MenuButton $width={"20%"} $maxWidth={"60px"} $tag={""} $fontSize={"80%"}>
                            <span className="material-symbols-outlined">
                                mode_of_travel
                            </span>
                        </MenuButton> */}
                        <MenuButton $width={"20%"} $maxWidth={"60px"} $tag={"departments"} $fontSize={"80%"} $action={ openMenuApartments }>
                            <span className="material-symbols-outlined">
                                {/* holiday_village */}
                                lists
                            </span>
                        </MenuButton>
                        
                        <MenuButton $width={"20%"} $maxWidth={"60px"} $tag={"daytime"} $fontSize={"80%"} $action={ openMenuDaytime }>
                            <span className="material-symbols-outlined">
                                routine
                            </span>
                        </MenuButton>
                        <MenuButton $width={"20%"} $maxWidth={"60px"} $tag={ "enviroment" } $fontSize={"80%"} $action={ updateVisibility }>
                            <span className="material-symbols-outlined">
                                { state.cityVisibility ? "domain_disabled" : "domain" }
                            </span>
                        </MenuButton>
                    </ActionsContainer>
                </ContainerActions>
            </MenuActions>

        </MenuWrapper>
    );
};

const MenuWrapper = styled.nav`
    max-width: 90%;
    min-width: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 20px 0 0;
`;

const MenuActions = styled.ul`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

`;

// const ContainerTime = styled.li`
//     width: 20%;
//     max-width: 200px;
//     position: relative;
// `;

const ContainerActions = styled.li`
    width: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    user-select: none;
`;

const ActionsContainer = styled.ul`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 0 10px;
    list-style: none;
`;

// const ActionItem = styled.li`
//     width: 20%;
//     max-width: 60px;
//     margin: 0 10px;

//     &:first-child{
//         margin: 0 5px 0 0;
//     }
    
//     &:last-child{
//         margin: 0 0 0 5px;
//     }
// `;

// const ActionButton = styled.a`
//     width: 100%;
//     height: 0;
//     padding-bottom: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-decoration: none;
//     ${ ({ theme })=>theme.styles.button }
//     position: relative;
//     cursor: pointer;

//     &:hover::before{
//         background-color: #ffffff30;
//     }

//     &::before{
//         display: block;
//         content: "";
//         width: calc(100% - 8px);
//         height: calc(100% - 8px);
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         background-color: #ffffff00;
//         border-radius: 12px;
//         transition: background-color 0.35s ease-out;
//     }

//     span{
//         ${ ({ theme })=>theme.utils.centerAbs }
//         color: ${ ({ theme })=>theme.color.green_100 };
//         font-size: ${ ({ theme })=>theme.utils.fluidType(34, 32) };
//     }
// `;



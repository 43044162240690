import { useGraph, useLoader, useThree } from "@react-three/fiber"
import { Box3, DoubleSide, FrontSide, Group, TextureLoader, TwoPassDoubleSide, Vector3 } from "three";
import { GLTFLoader, RGBELoader } from "three-stdlib";
import { animated as a, easings, useSpring, useTransition } from "@react-spring/three";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Outlines } from "@react-three/drei";
import { DepartmentFactory } from "../utils/DepartmentFactory";
import { FloorPosition } from "../utils/FloorPosition";
import { Department } from "../utils/Department";
import { DepartmentModel } from "./DepartmentModel";
import { PlaneCutter } from "./PlaneCutter";
import { CityView } from "./CityView";

export const CityLoader = ({ url })=>{

    const { gl } = useThree();

    const model = useLoader(GLTFLoader, url);
    const { nodes } = useGraph(model.scene);

    const mapTexture = useLoader(TextureLoader, './img/xenter-map-texture.png');
    mapTexture.flipY = false;
    mapTexture.anisotropy = gl.capabilities.getMaxAnisotropy();

    useEffect(()=>{

        FloorPosition.addPosition(FloorPosition.FPB, nodes.p_b);
        FloorPosition.addPosition(FloorPosition.F01, nodes.p_1);
        FloorPosition.addPosition(FloorPosition.F02, nodes.p_2);
        FloorPosition.addPosition(FloorPosition.F03, nodes.p_3);
        FloorPosition.addPosition(FloorPosition.F04, nodes.p_4);
        FloorPosition.addPosition(FloorPosition.F05, nodes.p_5);
        FloorPosition.addPosition(FloorPosition.F06, nodes.p_6);
        FloorPosition.addPosition(FloorPosition.F07, nodes.p_7);
        FloorPosition.addPosition(FloorPosition.F08, nodes.p_8);
        FloorPosition.addPosition(FloorPosition.F09, nodes.p_9);
        FloorPosition.addPosition(FloorPosition.F10, nodes.p_10);
        FloorPosition.addPosition(FloorPosition.F11, nodes.p_11);
        FloorPosition.addPosition(FloorPosition.F12, nodes.p_12);
        FloorPosition.addPosition(FloorPosition.F13, nodes.p_13);
        FloorPosition.addPosition(FloorPosition.F14, nodes.p_14);
        FloorPosition.addPosition(FloorPosition.FPH, nodes.r_t);

        new DepartmentFactory()
            .addGeometry(DepartmentFactory.T01, nodes.T1)
            .addGeometry(DepartmentFactory.T02, nodes.T2)
            .addGeometry(DepartmentFactory.T03, nodes.T3)
            .addGeometry(DepartmentFactory.T04, nodes.T4)
            .addGeometry(DepartmentFactory.T05, nodes.T5)
            .addGeometry(DepartmentFactory.T06, nodes.T6)
            .addGeometry(DepartmentFactory.T07, nodes.T7)
            .addGeometry(DepartmentFactory.T08, nodes.T8)
            .addGeometry(DepartmentFactory.T09, nodes.T9)
            .addGeometry(DepartmentFactory.T10, nodes.T10)
            .addGeometry(DepartmentFactory.T11, nodes.T11)
            .addGeometry(DepartmentFactory.T12, nodes.T12)
            .addGeometry(DepartmentFactory.T13, nodes.T13)
            .addGeometry(DepartmentFactory.T13N2, nodes.T13N2)
            .addGeometry(DepartmentFactory.T14, nodes.T14)
            .addGeometry(DepartmentFactory.T15, nodes.T15)
            .addGeometry(DepartmentFactory.T16, nodes.T16)
            .addGeometry(DepartmentFactory.T16N2, nodes.T16N2)
            .addGeometry(DepartmentFactory.T17, nodes.T17)
            .addGeometry(DepartmentFactory.T18, nodes.T18)
            .addGeometry(DepartmentFactory.TPH_A, nodes["PH_A"])
            .addGeometry(DepartmentFactory.TPH_B, nodes["PH_B"])
            .addGeometry(DepartmentFactory.TPH_C, nodes["PH_C"])
            .addGeometry(DepartmentFactory.A_GYM, nodes.gimnacio)
            .addGeometry(DepartmentFactory.A_LUDOTECA, nodes.ludoteca)
            .addGeometry(DepartmentFactory.A_ROOF, nodes.roof_top)
            .addGeometry(DepartmentFactory.A_SALON, nodes.salon)
            .addGeometry(DepartmentFactory.A_S_LOUNGE, nodes.social_lounge)
            .addGeometry(DepartmentFactory.A_S_PLAY, nodes.social_play)
            .addGeometry(DepartmentFactory.A_S_WORK, nodes.social_work)
            .addGeometry(DepartmentFactory.A_SUM, nodes.sum)
            .addGeometry(DepartmentFactory.A_TERRAZA, nodes.terraza)
            .addGeometry(DepartmentFactory.A_WELLNESS, nodes.wellness)
            .addGeometry(DepartmentFactory.CUTTER, nodes.cutter);
    }, [nodes])

    

    return(
        <>
            {/* <PlaneCutter handler={nodes.handler_x} plane={ nodes.plane_x } axisOrigin="x" axisTarget="x"/>
            <PlaneCutter handler={nodes.handler_y} plane={ nodes.plane_y } axisOrigin="y" axisTarget="y"/>
            <PlaneCutter handler={nodes.handler_z} plane={ nodes.plane_z } axisOrigin="x" axisTarget="z" /> */}
            <CityView cityGeometry={ nodes.city.geometry } mapGeometry={ nodes.map.geometry } mapTexture={ mapTexture } />
            {/* <mesh geometry={ nodes.city.geometry } castShadow receiveShadow position={position} rotation={ rotation } scale={ scale }>
                <a.meshPhysicalMaterial color={0xe3f2fd} metalness={ 0 } roughness={ 0 } transmission={ 0.2 } transparent={ true } opacity={ opacity } visible={ visibility }/>
                <Outlines thickness={0.002} color={ 0x334b5e } transparent={ true } opacity={0.9} visible={ visibility } />
            </mesh>
            <mesh geometry={ nodes.map.geometry }  castShadow receiveShadow position={position} rotation={ rotation } scale={ scale } onClick={e=>{ e.stopPropagation(); }} onPointerDown={e=>{ e.stopPropagation(); }} >
                <meshStandardMaterial color={ 0xffffff }  metalness={ 0 } roughness={ 1 } map={ mapTexture } flatShading={ true } transparent={false} />
            </mesh> */}
        </>
    );
}
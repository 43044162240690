import styled from "styled-components";

export const LogoContainer = ()=>{
    return(
        <LogoWrapper>
            <img src="./img/xenter_logo_v_color.svg" alt="xener logo" draggable={ false }/>
        </LogoWrapper>
    );
}

const LogoWrapper = styled.div`
    width: 7%;
    max-width: 140px;
    position: absolute;
    top: 30px;
    left: 30px;
    user-select: none;

    @media (orientation: portrait){
        width: 13%;
        max-width: 120px;
    }


    img{
        width: 100%;
        user-select: none;
    }

`;